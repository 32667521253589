<template>
  <nuxt-link v-bind="$attrs" :to="toPath" @click.capture="handleClickLink">
    <slot></slot>
  </nuxt-link>
</template>

<script setup lang="ts">
import type { RouteLocation, RouteLocationRaw } from 'vue-router'

const { locale } = useI18n()
const localePath = useLocalePath()

const nuxtApp = useNuxtApp()
const attrs = useAttrs()

const props = defineProps<{
  to?: string | RouteLocation | RouteLocationRaw
}>()

// 透過 nuxt hook 來判斷是否正在切換頁面
const isLoading = ref(false)
const removePageStartHook = nuxtApp.hook('page:start', () => {
  isLoading.value = true
})
const removePageFinishHook = nuxtApp.hook('page:finish', () => {
  isLoading.value = false
})

onBeforeUnmount(() => {
  removePageStartHook()
  removePageFinishHook()
})

const handleClickLink = (e: MouseEvent) => {
  const linkTo = props.to as RouteLocation
  if (isLoading.value && !attrs.href && !linkTo?.hash) {
    e.preventDefault()
    e.stopPropagation()
  } else {
    // hook 的觸發時機會稍晚，若使用者連點仍會擋不到，因此這邊再多改一次 isLoading 的值
    isLoading.value = true
  }
}

// nuxt-i18n 的 type 定義尚未更新，此處先讓 ts 忽略
const toPath = computed(() => (props.to ? localePath(props.to, locale.value) : undefined))
</script>
